<template>
    <!-- Ajout de la classe `account-container` à la div parente -->
    <div class="account-container">
        <side-bar/>
        <h1 class="title-account">Mes Tags</h1>
        <!-- Ajout de la classe `data-table-container` autour du tableau -->
        <div class="data-table-container">
            <table class="data-table">
                <thead>
                    <tr>
                        <th>Tag ID</th>
                        <th>Nom</th>
                        <th>Espace (en GB)</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(tag, index) in tag_id" :key="index">
                        <td>{{ tag }}</td>
                        <td>{{ tag_name[index] }}</td>
                        <td>{{ espace[index] }}</td>
                        <td>
                            <!-- Réduis la taille des boutons en ajustant les classes -->
                            <button class="small-button" @click="openModal(index)">Renommer</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div v-if="showModal" class="modal">
            <div class="modal-content">
                <span class="close-icon" @click="closeModal">✖</span>
                <h2>Renommer le tag</h2>
                <input class="input-name" v-model="newTagName" placeholder="Nouveau nom">
                <div>
                    <button class="btn-modal" @click="renameTag">Valider</button>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import SideBar from '../components/SideBar.vue'
export default {
    components: {
      SideBar
    },
  data(){
    
    return {
        tag_id: ['bts_c077ffaa9098aaaa', 'bts_dfe7f0a89098aaa2', 'bts_8427709a032502e0', 'bts_c72f709a032502e0', ],
        tag_name: ['tag_1', 'tag_2', 'tag_3', 'tag_4'],
        espace: ['10', '20', '25', '36'],
        showModal: false,
        currentTagIndex: null,
        newTagName: '',
        email: ''
    }
  },
 mounted(){
    this.getTags();
 },
  methods: {
    
      openModal(index) {
          this.currentTagIndex = index;
          this.newTagName = this.tag_name[index];
          this.showModal = true;
      },

      closeModal() {
          this.showModal = false;
      },

      renameTag() {
          if (this.currentTagIndex !== null) {
              this.tag_name.splice(this.currentTagIndex, 1, this.newTagName);
              const url = this.$api.getRESTApiUri() + `/user/new-tag-name`;
				fetch(url, {
					method: 'PUT',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
							email: this.email,
							tag_id: this.tag_name,
					}),
				})
				// Converting to JSON
				.then(response => response.json())
				// Displaying results to console
				.then(json => console.log(json));
                this.closeModal();
        }
      },

    async getTags(){
            const user = JSON.parse(localStorage.getItem('user'))
            this.email = user.email
            const email = encodeURIComponent(this.email);
            const url = this.$api.getRESTApiUri() + `/users/email/${email}`;
            return fetch(url)
            .then(res => res.text())
            .then(async (result) => {
                const data = JSON.parse(result);
                this.tag_id = data.tag_attributed
                this.tag_name = data.tag_renamed
            })
            .catch(error => {
                console.log(error)
            });
    },
  }
}
</script>

<style scoped>
.account-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 80vh;
	background-color: var(--grisclair);
}

.data-table-container {
	width: 80%;
	padding: 20px;
	border-radius: 10px;
	background-color: white;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    max-height: calc(95vh - 200px);
    overflow-y: auto;
}

.title-account {
	color: var(--vertfonce);
	margin-bottom: 20px;
}

.data-table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    font-size: 18px;
    text-align: left;
    border: 1px solid var(--grisclair);
}

.data-table th, .data-table td {
    border: 1px solid var(--grisclair);
    padding: 12px 15px;
}

.data-table th {
    background-color: var(--gris);
    color: var(--gris)
    ;
}

.data-table tbody tr:nth-of-type(even) {
    background-color: #f5f5f5;
}

.btn-modal {
    background-color: var(--vertfonce);
    color: white !important;
    border: none;
    border-radius: 20px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.btn-modal:hover {
    background-color: var(--turquoise);
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    position: relative;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 300px;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 18px;
    color: var(--gris);
}

.close-icon:hover {
    color: var(--vertfonce);
}

.small-button {
    background-color: var(--vertfonce);
    color: white !important;
    border: none;
    border-radius: 10px;
    padding: 5px 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.small-button:hover {
    color: var(--violet);
}

</style>